















































import { Component, Prop, Vue, Mixins } from "vue-property-decorator";
import VModal from "@/components/VModal.vue";
import TriggerActivitiesMixin from "@/mixins/http/TriggerActivitiesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import CarrierSelect from "@/components/Carrier/Select.vue";
import ShippingMethodSelect from "@/components/ShippingMethod/Select.vue";
import { namespace } from "vuex-class";
import { Source } from "@/models";
import OrderStatusSelect from "@/components/OrderStatus/Select.vue";
import CarrierStatusSelect from "@/components/CarrierStatus/Select.vue";
import ActivityTypeSelect from "@/components/Activity/TypeSelect.vue";
import { showToast } from "@/utils/Toast";
import UpdateOrderStatus from "@/components/Activity/UpdateOrderStatus.vue";
import { NoCache } from "@/utils/Decorators";
import { TriggerActivity } from "@/models";
import ActivitySelect from "@/components/Activity/Select.vue";
import { easync } from "@/utils/http";
import { sourceStore } from "@/store/typed";

const saveRequestTag = "save_request_tag";

@Component({
  components: {
    VModal,
    UpdateOrderStatus,
    ActivitySelect,
    CarrierSelect,
    CarrierStatusSelect,
    OrderStatusSelect,
    ShippingMethodSelect,
  },
})
export default class TriggerActivityCreateModal extends Mixins(
  TriggerActivitiesMixin
) {
  @RegisterHttp(saveRequestTag) createRequest: RequestData;
  modal!: VModal;
  content = "";

  item: TriggerActivity = null;

  canceled!: any | null;

  mounted() {
    this.modal = this.$refs.modal as VModal;
  }

  get carrierStatus() {
      if(this.item?.triggerableType != "CarrierStatus") {
      return null;
    }
    return (
      this.item?.triggerableId || (this.item?.triggerable as any)?.id
    )?.toString();
  }

  get orderStatus() {
    if(this.item?.triggerableType != "OrderStatus") {
      return null;
    }
    return (
      this.item?.triggerableId || (this.item?.triggerable as any)?.id
    )?.toString();
  }
  get activity() {
    return this.item?.activityId || this.item?.activity?.id;
  }

  loading() {
    return this.createRequest?.loading;
  }

  onActivitySelected(activityId: string) {
    this.item.activityId = activityId;
  }

  onOrderStatusSelected(orderStatusId: string) {
    this.item.triggerableId = orderStatusId;
    this.item.triggerableType = "OrderStatus";
  }

  onCarrierStatusSelected(carrierStatus: string) {
    this.item.triggerableId = carrierStatus;
    this.item.triggerableType = "CarrierStatus";
  }

  get valid() {
    return (
      this.item?.activityId &&
      this.item?.triggerableId &&
      this.item?.triggerableType
    );
  }

  title() {
    return `Nuovo trigger`;
  }

  get hasContent(): boolean {
    return this.$slots.default != null;
  }

  protected onCancel() {
    if (this.canceled) {
      this.canceled();
    }

    this.canceled = null;
    this.modal.hide();
    this.reset();
  }

  edit(activity: TriggerActivity) {
    this.item = activity;
    this.modal.show();
  }

  create() {
    this.item = Object.assign({
      triggerableType: null,
      triggerableId: null,
      activityId: null,
    });
    this.modal.show();
  }

  save() {
    this.item.id ? this.updateTrigger() : this.createTrigger();
  }

  async createTrigger() {
    const [data, errors] = await easync(
      this.createTriggerActivity(
        { ...this.item, sourceId: sourceStore.currentSource.id },
        saveRequestTag
      )
    );

    if (errors) {
      showToast("Errore durante la creazione", { type: "error" });
      return;
    }

    if (!this.item.id) {
      this.$emit("success", data);
    }
    this.modal.hide();
    showToast("Azione creata!");
    this.reset();
  }

  async updateTrigger() {
    const [data, errors] = await easync(
      this.updateTriggerActivity(
        this.item.id,
        { ...this.item, sourceId: sourceStore.currentSource.id },
        saveRequestTag
      )
    );

    if (errors) {
      showToast("Errore durante la creazione", { type: "error" });
      return;
    }

    this.modal.hide();
    showToast("Azione creata!");
    this.reset();
  }

  protected onConfirm() {
    this.save();
  }

  protected onHidden() {
    if (this.canceled) {
      this.canceled();
    }
  }

  reset() {
    this.item = new TriggerActivity(undefined);
  }

  show() {
    this.modal.show();
  }
}
