
import { BASE_API_URL } from '@/utils/Constants';
import { Component, Vue } from 'vue-property-decorator';

const BASE_TRIGGER_ACTIVITY_URL = BASE_API_URL+"trigger_activities"

@Component
export default class TriggerActivitiesMixin extends Vue {

  getTriggerActivities(data: any, tag: string) {
    return this.$httpGetQuery(BASE_TRIGGER_ACTIVITY_URL, tag,data);
  }

  destroyTriggerActivity(id: string|number, tag: string) {
    return this.$httpDelete(BASE_TRIGGER_ACTIVITY_URL+"/"+id, tag);
  }

  createTriggerActivity(data: any, tag: string) {
    return this.$httpPost(BASE_TRIGGER_ACTIVITY_URL, tag, data);
  }

  updateTriggerActivity(id: string, data: any, tag: string) {
    return this.$httpPut(BASE_TRIGGER_ACTIVITY_URL+"/"+id, tag, data);
  }
}
