


















import { TriggerActivity } from "@/models";
import { Activity } from "@/models";
import ActivityItem from "@/components/Activity/Item.vue"
import { Vue, Component, Prop, Mixins } from "vue-property-decorator";
@Component({components: {ActivityItem}})
export default class TriggerActivityItem extends Mixins() {
  @Prop({ required: true }) readonly triggerActivities!: TriggerActivity[];

  private items: TriggerActivity[] = this.triggerActivities;
  private open = false;

onDelete(activity: Activity) {
  this.$emit('delete', (activity as any).triggerableActivityId)
}

onEdit(activity: Activity) {
  this.$emit('edit', (activity as any).triggerableActivityId)
}

  get triggerable() {
    return this.items[0].triggerable;
  }

  get triggerableType() {
    return this.items[0].formattedType;
  }

  get triggerableName() {
    return this.items[0].triggerableName;
  }

  get activities(): Activity[] {
    return this.items.reduce((a,b) => {
      (b.activity as any).triggerableActivityId = b.id
      a.push(b.activity)
      return a}, []);
  }
}
