


























































import { Source } from "@/models";
import CreateModal from "@/components/TriggerActivity/Create.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import Search from "@/components/VEntrySearch.vue";
import TriggerActivityItem from "@/components/TriggerActivity/Item.vue";
import {
  Vue,
  Component,
  Mixins,
  Watch,
  Ref,
} from "vue-property-decorator";
import { namespace } from "vuex-class";
import TriggerActivitiesMixin from "@/mixins/http/TriggerActivitiesMixin";
import { RegisterHttp } from "@/utils/Decorators";
import { RequestData } from "@/store/types/RequestStore";
import StoreSubscriber from "@/store/StoreSubscriber";
import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import VPageContentSticky from "@/components/VPageContentSticky.vue";
import EmptyView from "@/components/EmptyView.vue";
import { TriggerActivity } from "@/models";
import { NoCache } from "@/utils/Decorators";
import { easync } from "@/utils/http";
import { showToast } from "@/utils/Toast";
import { sourceStore } from "@/store/typed";

const getTag = "get_activities";
const destroyTag = "destroy_activity";

@Component({
  components: {
    TriggerActivityItem,
    EmptyView,
    Search,
    VPageContentSticky,
    DataTable,
    CreateModal,
    ConfirmModal,
  },
})
export default class TriggerActivityList extends Mixins(
  TriggerActivitiesMixin
) {
  @RegisterHttp(getTag) getRequest: RequestData;
  @RegisterHttp(destroyTag) destroyRequest: RequestData;
  @Ref() createModal: HTMLDivElement;
  @Ref() editModal: HTMLDivElement;
  @Ref() confirmModal: HTMLDivElement;

  items: TriggerActivity[] = [];
  page = 1;
  show = true;

  columns = [
    { name: "name", label: "Nome" },
    { name: "status", label: "Stato" },
    { name: "actions", label: "" },
  ];

  search: string | null = null;

  created() {
    this.subscribe();
  }

  @NoCache
  get groupedItems() {
    const map: { [id: string]: TriggerActivity[] } = {};

    this.items.forEach((o) => {
      if (map[o.triggerableId]) {
        map[o.triggerableId].push(o);
      } else {
        map[o.triggerableId] = [o];
      }
    });
    return map;
  }

  onCreated(item: TriggerActivity) {
    this.items.push(item);
    this.show = false;
    this.$nextTick(() => {
      this.loadItems();
    });
  }

  onUpdated(item: TriggerActivity) {
    const index = this.items.findIndex((o) => item.id === o.id);
    Vue.set(this.items, index, item);
  }

  async searchItems(name: string) {
    if (name.trim().length > 0) {
      this.search = name;
      const items = await this.getTriggerActivities(this.payload, getTag);
      return items;
    }
  }

  onSearchCanceled() {
    this.page = 1;
    this.search = null;
    this.loadItems();
  }

  onSearched(items: any) {
    this.page = 1;
    this.items = items.items;
  }

  onDeleted(id: string) {
    this.items = this.items.filter((o) => id !== o.id);
    this.show = false;
    this.loadItems();
  }

  openCreateModal() {
    (this.createModal as any).create();
  }

  openEditModal(id: string) {
    const item = this.items.find((o) => o.id === id);
    (this.createModal as any).edit(item);
  }

  async openConfirmModal(id: string) {
    const message = "Sei sicuro di eliminare questo trigger";
    const confirmed = await (this.confirmModal as any).confirm(message);

    if (confirmed) {
      this.destroyItem(id);
    }
  }

  @Watch("page")
  onPageChanged(value: any, oldValue: any) {
    if (value == 1 && oldValue) {
      this.items = [];
    }
    this.loadItems();
  }

  async destroyItem(id: string) {
    const [data, errors] = await easync(this.destroyTriggerActivity(id, destroyTag));
    if (errors) {
      showToast("Si è verificato un errore", { type: "errore" });
      return;
    }

    this.onDeleted(id);
  }

  mounted() {
    if (sourceStore.currentSource) {
      this.loadItems();
    }
  }

  subscribe() {
    StoreSubscriber.subscribe("source/setCurrentSource", this.onSourceSet);
  }

  onSourceSet(source: Source | undefined) {
    if (source) {
      this.loadItems();
    }
  }

  nextPage() {
    this.page += 1;
  }

  get payload() {
    const data = {
      per: 20,
      include: ["name"],
      page: this.page,
      filter: {
        source: sourceStore.currentSource?.id,
        term: this.search,
      },
    };

    return data;
  }

  async loadItems() {
    const [data, errors] = await easync(this.getTriggerActivities(this.payload, getTag));
    if (errors) {
      if (this.page > 1) {
        this.page -= 1;
      }
      return;
    }

    if (this.page === 1) {
      this.items = [];
    }
    this.items.push(...data);
    this.show = true;
  }

  get loading() {
    return this.getRequest?.loading;
  }

  get meta() {
    return this.getRequest?.meta;
  }
}
